import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import device from "vue-device-detector"

Vue.use(device)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'
import './assets/css/light.css'
import './assets/css/minigame68.css'
import './assets/fontawesome/css/all.css'
import 'animate.css'
import VueGallery from 'vue-gallery'

Vue.component('gallery', VueGallery)
new Vue({
    render: h => h(App),
    router,
    store,
    data: function () {
        return {
            api_url: process.env.VUE_APP_API_URL,
            app_name: process.env.VUE_APP_NAME,
            global_settings:{}
        }
    },
    methods: {

    },
    computed:{

    },
    created() {
    }
}).$mount('#app')
