<template>
  <div id="app">
    <slot>
      <div class="site-content">
        <router-view></router-view>
      </div>
    </slot>
  </div>
</template>
<script>
  export default {
    name: 'App',
    data: function () {
      return {
      }
    },
    computed: {

    },
    methods: {
    },
    created() {

    },
  }
</script>

<style>

</style>